import { useSessionStorage } from "@vueuse/core";

export default () => {
  const route = useRoute();
  const isApp = useSessionStorage(storage.subscriptionsFromApp, false);

  onMounted(async () => {
    if (route.query.is_app === "true") {
      isApp.value = true;
    }
  });

  return {
    isApp,
  };
};
